import React, {useCallback, useEffect, useState} from 'react';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../errors';
import styled from 'styled-components';
import Row from '../Widgets/Row';
import Button from '../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Anchor from '../Widgets/Anchor';
import UserGroupDialog from '../Dialogs/UserGroupDialog';
const qs = require('query-string');
const appConfig = require('../data.json');

const PAGINATION = {
  current: 1,
  pageSize: 20,
};

export default function UserGroupListPage(props) {
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');
  const [total, setTotal] = useState(0);
  const [records, setRecords] = useState([]);
  const [filters, setFilters] = useState({
    ...PAGINATION,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const getRecords = useCallback(async () => {
    actions.setLoading(true);
    try {
      let resp = await actions.getUserGroups({});
      setRecords(resp);
      // setTotal(resp.total);
      // setRecords(resp.records);
    } catch (err) {
      errorHandler(err);
    }
    actions.setLoading(false);
  }, []);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <h2
          style={{
            fontSize: '1.25rem',
            margin: 0,
          }}>
          會員群組列表
        </h2>
        <div style={{flex: 1}} />
        <Button
          type="primary"
          onClick={() => setOpenDialog(true)}
          style={{marginRight: 10}}
          color={appConfig.colors.sub}>
          新增會員群組
        </Button>
        <Button
          type="primary"
          onClick={getRecords}
          icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
          刷新
        </Button>
      </Row>

      <Ant.Table
        dataSource={records}
        columns={[
          {
            title: '編號',
            key: 'id',
            dataIndex: 'id',
            render: (id) => <Anchor to={`/user_group/?id=${id}`}>#{id}</Anchor>,
          },
          {
            title: '名稱',
            key: 'name',
            dataIndex: 'name',
          },
          {
            title: '群組備註',
            key: 'note',
            dataIndex: 'note',
          },
        ]}
        rowKey="id"
        pagination={false}
        // pagination={{
        //   pageSize: filters.pageSize,
        //   current: filters.current,
        //   total,
        // }}
        // onChange={(pagination) => {
        //     setFilters(prev => ({
        //       ...prev,
        //       pageSize: pagination.pageSize,
        //       current: pagination.current,
        //     }))
        // }}
      />
      <UserGroupDialog
        visible={openDialog}
        onClose={() => setOpenDialog(false)}
        onUpdate={getRecords}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
